<template>
  <div class="goods-details">
    <div class="title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/goods/list' }">商品列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{breadcrumb}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 表单数据 -->
    <div class="myform">
      <el-form :model="goodsForm" :rules="rules" ref="rulForm" class="demo-ruleForm">
        <div class="form-top">
          <el-form-item label="商品名称" prop="name">
            <el-input class="form-input" size="small" v-model="goodsForm.name"></el-input>
          </el-form-item>
          <div class="form-compo-title">
            <div>商品规格</div>
            <div class="form-compo-title-img" @click="addSpecification">
              <span>添加规格</span>
              <el-image class="img" :src="require(`../../../assets/image/add.png`)"></el-image>
            </div>
          </div>
          <div class="form-compo" v-for="(value, index) in goodsForm.specification" :key="value.specification_id">
            <div class="form-compo-div">
              <el-form-item
                label="名称："
                :prop="'specification' + index"
                >
                <el-input class="form-input-small" size="small" v-model="value.name"></el-input>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item
                label="单价："
                :prop="'goods_price' + index"
                >
                <el-input class="form-input-small" size="small" v-model="value.goods_price"></el-input>
              </el-form-item>
            </div>
            <div>
              <el-image class="form-compo-img" @click="removeSpecification(value)" v-if="index !== 0" :src="require(`../../../assets/image/del.png`)"></el-image>
            </div>
          </div>
        </div>
        <div class="form-center">
          <div class="form-pic-title">商品图</div>
          <div class="form-pic-compo">
            <el-form-item prop="goods_img">
              <div class="form-pic">
                <el-image v-if="showImg1" class="pic-img" :src="goodsForm.goods_img"></el-image>
                <el-image v-if="showImg2" class="pic-img" :src="goodsForm.goods_attach_img"></el-image>
                <el-image v-if="showImg3" class="pic-img" :src="goodsForm.goods_end_img"></el-image>
                <div class="avatar-uploader">
                  <i class="el-icon-plus avatar-uploader-icon" @click="addPic"></i>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="form-pic-title">活动描述</div>
          <div class="form-pic-desc">
            <el-form-item class="form-desc" prop="long_pic" lazy>
              <el-image v-if="showImg4" class="pic-img" :src="goodsForm.long_pic"></el-image>
            </el-form-item>
            <div class="avatar-uploader">
              <i class="el-icon-plus avatar-uploader-icon" @click="addLongPic"></i>
            </div>
          </div>
        </div>
        <div class="form-bottom">
          <div class="form-bottom-compo">
            <el-form-item label="分佣比例" prop="commission">
              <el-input class="form-input" size="small" v-model="goodsForm.commission"></el-input>
              <span class="ml">%</span>
            </el-form-item>
            <el-form-item label="赚取佣金" prop="commission_price">
              <el-input class="form-input" size="small" v-model="goodsForm.commission_price"></el-input>
            </el-form-item>
            <span class="comment">（备注：可以不填，默认使用分佣比例计算佣金）</span>
          </div>
          <span style="color: red;">只允许添加一个课程，遗留问题！</span>
          <el-form-item label="课程列表" prop="audioList">
            <el-transfer v-model="goodsForm.audioList" :data="audioSmList" :titles="['全部课程', '已选中课程']"></el-transfer>
          </el-form-item>
        </div>
        <div class="form-button">
          <el-form-item>
            <el-button type="warning" size="mini" @click="submitForm">确定</el-button>
            <el-button size="mini" @click="resetForm">取消</el-button>
          </el-form-item>
        </div>
        <div style="height: 100px;"></div>
      </el-form>
    </div>
    <!-- 弹窗 -->
    <MyDialog :dialogVisible="dialogVisible" @changeDialog="changeDialog" @getPic="getPic" :limit="limit" />
  </div>
</template>

<script>
import MyDialog from '../../../components/MyDialog.vue'
import { addGoods, getDetails, shopAudioSmlist } from '../../../request/api'

export default {
  components: {
    MyDialog
  },
  data() {
    return {
      goodsForm: {
        name: '',
        goods_img: '',
        goods_attach_img: '',
        goods_end_img: '',
        long_pic: '',
        shop_goods_id: '',
        audioList: [],
        specification: [
          {
            name: '',
            goods_price: '',
            specification_id: Date.now()
          }
        ],
        commission: '',
        commission_price: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入商品名称', trigger: 'blur' }
        ],
        goods_img: [
          { required: true, message: '请上传商品图片', trigger: 'change' }
        ]
      },
      dialogVisible: false,
      limit: 0,
      showImg1: false,
      showImg2: false,
      showImg3: false,
      showImg4: false,
      breadcrumb: '新增商品',
      audioSmList: []
    };
  },
  methods: {
    async submitForm() {
      this.$refs.rulForm.validate(valid => {
        if (!valid) {
          console.log('error submit!')
          return false
        }
      })
      if (this.goodsForm.name === '' || this.goodsForm.goods_img === '') {
        this.$message.warning('请完善字段信息')
        return
      }
      this.goodsForm.specification = JSON.stringify(this.goodsForm.specification)

      const res = await addGoods(this.goodsForm)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      this.$message.success('需发布后才能生效')
      this.$router.push({ path: '/goods/list' })
    },
    resetForm() {
      this.$router.back()
    },
    // 上传商品图片 限制3张
    addPic () {
      this.limit = 3
      this.dialogVisible = true
    },
    // 上传活动描述 限制1张
    addLongPic () {
      this.limit = 1
      this.dialogVisible = true
    },
    // 取消按钮 关闭弹窗
    changeDialog () {
      this.dialogVisible = false
    },
    // 组件保存图片的回调
    getPic (picArr, id) {
      this.dialogVisible = false
      picArr.forEach((pic, index) => {
        if (id === 3) {
          if (index === 0) {
            this.goodsForm.goods_img = pic
            this.showImg1 = true
          } else if (index === 1) {
            this.goodsForm.goods_attach_img = pic
            this.showImg2 = true
          } else {
            this.goodsForm.goods_end_img = pic
            this.showImg3 = true
          }
        } else {
          this.goodsForm.long_pic = pic
          this.showImg4 = true
        }
      })
    },
    // 新增规格
    addSpecification () {
      this.goodsForm.specification.push({
        name: '',
        goods_price: '',
        specification_id: Date.now()
      })
    },
    // 删除规格
    removeSpecification (value) {
      let index = this.goodsForm.specification.indexOf(value)
      if (index !== -1) {
        this.goodsForm.specification.splice(index, 1)
      }
    },
    // 初始化操作
    async init () {
      // 初始化全部课程
      const s = await shopAudioSmlist(this.goodsForm.shop_goods_id)
      if (s.status !== 200 || s.data.code !== '1000') this.$message.error(s.data.msg)
      s.data.data.audioList.forEach(element => {
        this.audioSmList.push({
          key: element.shop_lesson_id,
          label: element.lesson_name
        })
      })

      if (this.goodsForm.shop_goods_id !== undefined) {
        this.breadcrumb = '编辑商品'
        // 初始化已选中课程
        this.goodsForm.audioList = s.data.data.goods_arr

        const res = await getDetails(this.goodsForm.shop_goods_id)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        this.goodsForm.name = res.data.data.name
        this.goodsForm.goods_img = res.data.data.goods_img[0]
        this.goodsForm.specification = res.data.data.specification
        this.goodsForm.commission = res.data.data.commission
        this.goodsForm.commission_price = res.data.data.commission_price
        this.showImg1 = true
        if (res.data.data.goods_img[1] !== '') {
          this.goodsForm.goods_attach_img = res.data.data.goods_img[1]
          this.showImg2 = true
        }
        if (res.data.data.goods_img[2] !== '') {
          this.goodsForm.goods_end_img = res.data.data.goods_img[2]
          this.showImg3 = true
        }
        if (res.data.data.long_pic !== '') {
          this.goodsForm.long_pic = res.data.data.long_pic
          this.showImg4 = true
        }
      }
    }
  },
  created () {
    this.goodsForm.shop_goods_id = this.$route.query.shop_goods_id
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.goods-details {
  margin: 10px;
  height: 100%;
  .title {
    padding: 10px;
    background: #fff;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-bottom: 10px;
  }
  .myform {
    height: 100%;
    .demo-ruleForm {
      height: 100%;
      .form-top {
        background: #fff;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        .form-compo-title {
          border-bottom-style: solid;
          border-bottom-color: #C4C4C4;
          border-bottom-width: 2px;
          width: 648px;
          display: flex;
          margin-bottom: 8px;
          justify-content: space-between;
          font-size: 14px;
          font-weight: 400;
          .form-compo-title-img {
            color: #0062C2;
            display: flex;
            width: 76px;
            cursor: pointer;
            .img {
              width: 14px;
              height: 14px;
              margin-top: 3px;
              margin-left: 2px;
            }
          }
        }
        .form-input {
          width: 572px;
        }
        .form-input-small {
          width: 264px;
        }
        .form-compo {
          display: flex;
          .form-compo-img {
            cursor: pointer;
            margin-top: 10px;
          }
          .form-compo-div {
            width: 330px;
          }
        }
      }
      .form-center {
        background: #fff;
        margin-top: 10px;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        .form-pic-title {
          border-bottom-style: solid;
          border-bottom-color: #C4C4C4;
          border-bottom-width: 2px;
          width: 644px;
          margin-bottom: 8px;
          font-size: 14px;
          font-weight: 400;
        }
        .form-pic-compo {
          .form-pic {
            display: flex;
            align-items: center;
            .avatar-uploader {
              border: 1px dashed #d9d9d9;
              border-radius: 6px;
              cursor: pointer;
              position: relative;
              overflow: hidden;
              .avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 120px;
                height: 120px;
                line-height: 120px;
                text-align: center;
              }
            }
            .avatar-uploader:hover {
              border-color: #409eff;
            }
            .pic-img {
              width: 120px;
              height: 120px;
              margin-right: 10px;
            }
          }
        }
        .form-pic-desc {
          display: flex;
          align-items: center;
          .avatar-uploader {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            .avatar-uploader-icon {
              font-size: 28px;
              color: #8c939d;
              width: 120px;
              height: 120px;
              line-height: 120px;
              text-align: center;
            }
          }
          .avatar-uploader:hover {
            border-color: #409eff;
          }
          .form-desc {
            overflow: auto;
            height: 120px;
            margin-right: 10px;
            .pic-img {
              width: 120px;
            }
          }
        }
      }
      .form-bottom {
        background: #fff;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin-top: 10px;
        .form-bottom-compo {
          display: flex;
          .comment {
            padding-top: 50px;
            font-size: 12px;
            color: #858585;
          }
          .form-input {
            width: 188px;
          }
          .ml {
            margin: 0px 10px;
          }
        }
      }
      .form-button {
        background: #fff;
        padding: 20px 0px 2px 20px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 10px 0px;
      }
    }
  }
}
</style>